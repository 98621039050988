import React from "react";

import { useParams } from "react-router";

import { useQuery, useMutation } from "@apollo/client";

import task from "./task";

import mapTaskValues from "./map-task-values";
import updateTask from "./update-task";

import TaskUpdate from "./task-update";

interface Props {
  onClose?: any;
  onSuccess?: any;
  onError?: any;
}

function TaskUpdateContainer(props: Props) {
  const { onClose } = props;

  const { id } = useParams<any>();

  const defaultValues = {};

  const { data /*, error*/ } = useQuery(task, {
    variables: { where: { id: id /*: entityId*/ } },
  });

  // if (error) return `Error! ${error.message}`;

  const entity = data ? data.task : undefined;
  const { /*entity,*/ onSuccess /*, onError*/ } = props;

  const [updateTaskMutation] = useMutation(updateTask);
  async function saveEntity(componentValues: any) {
    const { id, __typename, ...valuesRest } = componentValues;
    const options: any = {
      variables: { input: { ...mapTaskValues(valuesRest, "update") } },
      refetchQueries: ["tasks"],
    };
    options.variables.where = { id: entity.id };
    /*const result =*/ await updateTaskMutation(options);

    if (onSuccess) {
      onSuccess();
    }
  }

  return (
    <TaskUpdate
      saveEntity={saveEntity}
      cancelEdit={onClose}
      entity={entity}
      defaultValues={defaultValues}
    ></TaskUpdate>
  );
}

export default TaskUpdateContainer;
