import React from "react";
import PropTypes from "prop-types";

// import { Link } from "react-router-dom";

import FullCalendar, { EventApi, DateSelectArg, EventClickArg, EventContentArg, formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { values } from "lodash";

// import Button from "@material-ui/core/Button";
// import Checkbox from "@material-ui/core/Checkbox";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // table: {
    //   minWidth: 700,
    // },
  })
);

// TODO https://github.com/fullcalendar/fullcalendar-example-projects/blob/master/react-typescript/

interface Props {
  dataSource: { [key: string]: any }[];
  changeVariables: any;
  loading: boolean;
  totalElements?: number;

  // TODO probably this belongs to the container!
  createEntityMutation?: any;
  updateEntityMutation?: any;
  deleteEntityMutation?: any;

  // TODO naming! (e.g., onViewClick, onEditClick, onDeleteClick)
  onView: any;
  onEdit: any;
  onDelete: any;
}

function TaskCalendar(props: Props) {
  const {
    dataSource = [],
    changeVariables,
    loading,
    totalElements,
    createEntityMutation,
    updateEntityMutation,
    deleteEntityMutation,
    onView,
    onEdit,
    onDelete,
  } = props;
  // const { dataSource, onEdit, onDelete } = props;
const events = (dataSource || []).map((value: any) => ({id: value.id, title: value.title, start: value.date/*, allDay: true*/}));
  const classes = useStyles(props);
  return (
    <FullCalendar
    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
    headerToolbar={{
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    }}
    initialView='dayGridMonth'
    editable={true}
    selectable={true}
    selectMirror={true}
    dayMaxEvents={true}
    events={events}
    height="100%" // TODO remove height 100%
    // weekends={this.state.weekendsVisible}
    // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
    // select={this.handleDateSelect}
    // eventContent={renderEventContent} // custom render function
    // eventClick={this.handleEventClick}
    // eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
    /* you can update a remote database when these fire:
    eventAdd={function(){}}
    eventChange={function(){}}
    eventRemove={function(){}}
    */
    // eventAdd={function(){}}
    // eventChange={function(){}}
    eventRemove={(arg) => {onDelete(dataSource.find(e => e.id === arg.event.id)/*, event*/)}} // TODO Use the arg.revert method if user cancels
  />
    // <Table className={classes.table}>
    //   <TableHead>
    //     <TableRow>
    //       {/* <TableCell>Id</TableCell> */}
    //       <TableCell>Type</TableCell>
    //       <TableCell>Title</TableCell>
    //       <TableCell>Date</TableCell>
    //       <TableCell>Fields</TableCell>
    //       <TableCell>Product</TableCell>
    //       <TableCell>Amount</TableCell>
    //       <TableCell>Unit</TableCell>
    //       <TableCell>Edit</TableCell>
    //       <TableCell>Delete</TableCell>
    //     </TableRow>
    //   </TableHead>
    //   <TableBody>
    //     {dataSource &&
    //       dataSource.map((row) => (
    //         <TableRow key={row.id}>
    //           {/* <TableCell>{row.id}</TableCell> */}
    //           <TableCell>{JSON.stringify(row.type, null, 2)}</TableCell>
    //           <TableCell>{row.title}</TableCell>
    //           <TableCell>{JSON.stringify(row.date, null, 2)}</TableCell>
    //           <TableCell>{row.fields ? row.fields.length : 0}</TableCell>
    //           <TableCell>{row.product}</TableCell>
    //           <TableCell>{JSON.stringify(row.amount, null, 2)}</TableCell>
    //           <TableCell>{row.unit}</TableCell>
    //           <TableCell>
    //             <Button
    //               onClick={(event: any) => onEdit(row, event)}
    //               variant="contained"
    //               color="primary"
    //             >
    //               Edit
    //             </Button>
    //           </TableCell>
    //           <TableCell>
    //             <Button
    //               onClick={(event: any) => onDelete(row, event)}
    //               variant="contained"
    //               color="primary"
    //             >
    //               Delete
    //             </Button>
    //           </TableCell>
    //         </TableRow>
    //       ))}
    //   </TableBody>
    // </Table>
  );
}

export default TaskCalendar;
