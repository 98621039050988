import { /*loader,*/ gql } from "graphql.macro";

const fields = gql`
  query fields(
    $where: FieldWhereInput
    $orderBy: FieldOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    fields(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) @rest(type: "Field", path: "fields/?{args}") {
      id
      name
      location
    }

    # fieldCount @defer @rest(type: "Int", path: "fields/count")
  }
`;

export default fields;
