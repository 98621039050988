import { /*loader,*/ gql } from "graphql.macro";

const tasks = gql`
  query tasks(
    $where: TaskWhereInput
    $orderBy: TaskOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    tasks(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) @rest(type: "Task", path: "tasks/?{args}") {
      id
      type
      title
      date
      fields
      product
      amount
      unit
    }

    # taskCount @defer @rest(type: "Int", path: "tasks/count")
  }
`;

export default tasks;
