import React from "react";

import { useHistory, useLocation } from "react-router";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import AddIcon from "@material-ui/icons/Add";

import FieldTable from "./field-table";
import FieldCreate from "./field-create";
import FieldDetail from "./field-detail";
import FieldUpdate from "./field-update";
import FieldDelete from "./field-delete";

import FieldTableContainer from "./field-table-container";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  })
);

interface Props {}

function FieldOverview(props: Props) {
  const location = useLocation();
  const history = useHistory();

  const classes = useStyles(props);
  function handleCreateClick(event: any) {
    // history.push(`${match.url}/new`);
    history.push(`${location.pathname}/new`);
    event.stopPropagation();
  }

  function handleClose(event?: any) {
    if (event) {
      event.stopPropagation();
    }
    history.goBack();
  }
  return (
    <div>
      <Grid justify="space-between" alignItems="center" container>
        <Grid item>
          <Typography variant="h5">Fields</Typography>
        </Grid>
        <Grid item>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleCreateClick}
          >
            Create
          </Button>
        </Grid>
      </Grid>
      <FieldTableContainer></FieldTableContainer>
    </div>
  );
}

export default FieldOverview;
