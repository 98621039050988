import mapFieldValues from "./map-field-values";

export default function (
  values: any,
  method: string,
  originalValues: any = {}
): any {
  const result: any = {};
  result.id = values ? values.id : undefined;
  result.type = values ? values.type : undefined;
  result.title = values ? values.title : undefined;
  result.date = values ? values.date.toJSON() : undefined;
  result.fields = values.fields
    ? values.fields.map((v: any) => mapFieldValues(v, method))
    : [];
  result.product = values ? values.product : undefined;
  result.amount = values ? values.amount : undefined;
  result.unit = values ? values.unit : undefined;
  return result;
}
