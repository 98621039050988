import React from "react";

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      // width: "auto",
      // display: "block", // Fix IE 11 issue.
      // marginLeft: theme.spacing(3),
      // marginRight: theme.spacing(3),
      // [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      //   width: 400,
      //   marginLeft: "auto",
      //   marginRight: "auto",
      // },
    },
    paper: {
      // marginTop: theme.spacing(8),
      // display: "flex",
      // flexDirection: "column",
      // alignItems: "center",
      // padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      //   3
      // )}px`,
    },
    root: {
      // maxWidth: 345,
      marginBottom: theme.spacing(2)
    },
    media: {
      height: 140,
    },
  })
);

interface Props {}

function MainPage(props: Props) {
  const classes = useStyles(props);

  return (
    <main className={classes.main}>
      <Container>
      {/* <Paper className={classes.paper}> */}
        <Typography variant="h3" component="h1" color="primary" align="center" gutterBottom>Willkommen zur ExoFarm Demo!</Typography>
        <Typography variant="body1" color="error" align="center" gutterBottom>Achtung: ExoFarm befindet sich derzeit in Entwicklung! Diese Webanwendung dient ausschließlich Demonstrationszwecken und kann nicht für den Produktiveinsatz verwendet werden!</Typography>

        {/* <Link to="/login">Login</Link>
        <Link to="/signup">Signup</Link> */}
      {/* </Paper> */}
{/* TODO <Grid */}
        <Card className={classes.root}>
          {/* <CardActionArea> */}
            <CardMedia
              className={classes.media}
              image={`${process.env.PUBLIC_URL}/static/images/cards/barley-871994_1280.jpg`}
              title="Barley Field"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Digitale Ackerschlagkartei
          </Typography>
              <Typography variant="body1" component="p">
                ExoFarm ist ein sogennantes Farm Management Informations-Systeme (FMIS).
                Bodenbearbeitung, Aussaat, Düngung, Pflanzenschutz, Ernte und andere Tätigkeiten können bequem im Browser, Smartphone oder am Tablet verwaltet werden.
                Digitale Landwirtschaft ist ein Trend, der viele Möglichkeiten eröffnet. Durch die Auswertung der Informationen können Entscheidungen besser getroffen werden.
                Somit hilft das System Kosten einzusparen und Erträge zu maximieren. Außerdem profitiert die Umwelt, wenn Düngemittel und Pflanzenschutz optimal eingesetzt wird.
          </Typography>
            </CardContent>
          {/* </CardActionArea> */}
        </Card>
        {/* <Card className={classes.root}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Einfach loslegen
              </Typography>
              <Typography variant="body1" component="p">
                <ol>
                  <li>Schläge anlegen</li>
                  <li>Tätigkeiten verwalten</li>
                </ol>
              </Typography>
            </CardContent>
        </Card> */}
      </Container>
    </main>
  );
}

export default MainPage;
