import { /*loader,*/ gql } from "graphql.macro";

const deleteField = gql`
  mutation deleteField($where: FieldWhereUniqueInput!) {
    deleteField(where: $where)
      @rest(type: "Field", path: "fields/{args.where.id}", method: "DELETE") {
      id
    }
  }
`;

export default deleteField;
