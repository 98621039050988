import { /*loader,*/ gql } from "graphql.macro";

const createTask = gql`
  mutation createTask($input: TaskCreateInput!) {
    createTask(input: $input)
      @rest(type: "Task", path: "tasks/", method: "POST") {
      id
      type
      title
      date
      fields
      product
      amount
      unit
    }
  }
`;

export default createTask;
