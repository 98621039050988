import React, { useState } from "react";

import { useHistory, useLocation } from "react-router";

import { useQuery } from "@apollo/client";

import fields from "./fields";

import FieldTable from "./field-table";
import EditControlExample from "./GeoJsonPolygonField";
import field from "./field";

interface Props {}

function FieldTableContainer(props: Props) {
  const location = useLocation();
  const history = useHistory();
  function handleView(value: any, event?: any) {
    if (event) {
      event.stopPropagation();
    }
    history.push(`${location.pathname}/${value.id}`);
    // history.push(`${match.url}/${value.id}`);
  }
  function handleEdit(value: any, event?: any) {
    if (event) {
      event.stopPropagation();
    }
    history.push(`${location.pathname}/${value.id}/edit`);
    // history.push(`${match.url}/${value.id}/edit`);
  }
  function handleDelete(value: any, event?: any) {
    if (event) {
      event.stopPropagation();
    }
    history.push(`${location.pathname}/${value.id}/delete`);
    // history.push(`${match.url}/${value.id}/delete`);
  }

  const [variables, setVariables] = useState({});

  const { data /*, error*/, loading } = useQuery(fields, {
    variables,
  });

  // if (error) return `Error! ${error.message}`;

  const dataSource = data ? data.fields : undefined;
  const totalElements = data ? data.fieldCount : undefined;
  function changeVariables(newVariables: any) {
    if (JSON.stringify(variables) !== JSON.stringify(newVariables)) {
      setVariables(newVariables);
    }
  }
  return (
    <>
    <EditControlExample data={
      {
        type: "FeatureCollection",
        features: 
        (dataSource || []).map((field: any) => ({
          type: "Feature",
          geometry: field.location
        }))
      }
    } readOnly={true} />
    {/* {dataSource && <FieldTable
      dataSource={dataSource}
      totalElements={totalElements}
      changeVariables={changeVariables}
      loading={loading}
      onView={handleView}
      onEdit={handleEdit}
      onDelete={handleDelete}
    ></FieldTable>} */}
    </>
  );
}

export default FieldTableContainer;
