import React, { Component, useEffect, useRef, useState } from 'react';
import { MapContainer, MapConsumer, FeatureGroup, useMap } from 'react-leaflet';
import L from 'leaflet';
import { EditControl } from "react-leaflet-draw"

import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

import { showErrorOnChange } from 'mui-rff';

import "leaflet/dist/leaflet.css";

import "leaflet-draw/dist/leaflet.draw.css";

// // import "react-leaflet-markercluster/dist/styles.css";
// import "react-leaflet-markercluster/dist/styles.min.css";
// import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
// import "leaflet-extra-markers/dist/js/leaflet.extra-markers.min.js";

import "./leaflet-custom.css";

import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

const defaultLocation: L.LatLngExpression = [47.6964719, 13.3457347];

export type GeoJsonPolygonProps = Partial<Omit<any/*TODO MapContainerProps*/, 'type' | 'onChange'>> & {
	name: string;
	// type?: TEXT_FIELD_TYPE; // TODO
	fieldProps?: Partial<FieldProps<any, any>>;
	// showError?: ShowErrorFunc; // TODO
};

export function GeoJsonPolygon(props: GeoJsonPolygonProps) {
	const { name, type, fieldProps, ...rest } = props;

	return (
		<Field
			name={name}
			type={type}
			render={({ input, meta }) => (
				<GeoJsonPolygonWrapper input={input} meta={meta} name={name} type={type} {...rest} />
			)}
			{...fieldProps}
		/>
	);
}

type GeoJsonPolygonWrapperProps = FieldRenderProps<any/*TODO MapContainerProps*/, HTMLElement>;

export function GeoJsonPolygonWrapper(props: GeoJsonPolygonWrapperProps) {
	const {
		input: { name, value, type, onChange, ...restInput },
		meta,
		required,
		fullWidth = true,
		helperText,
		showError = showErrorOnChange,
		...rest
	} = props;

	const { error, submitError } = meta;
	const isError = showError({ meta });
    // console.log("GeoJsonPolygon field value: ", value);

	return (
        <EditControlExample data={value} onChange={onChange} />
		// <MuiGeoJsonPolygon
		// 	fullWidth={fullWidth}
		// 	helperText={isError ? error || submitError : helperText}
		// 	error={isError}
		// 	onChange={onChange}
		// 	name={name}
		// 	value={value}
		// 	type={type}
		// 	required={required}
		// 	inputProps={{ required, ...restInput }}
		// 	{...rest}
		// />
	);
}




// work around broken icons when using webpack, see https://github.com/PaulLeCam/react-leaflet/issues/255

// delete L.Icon.Default.prototype._getIconUrl;
// L.Icon.Default.mergeOptions({
//   iconRetinaUrl:
//     'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
//   iconUrl:
//     'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
//   shadowUrl:
//     'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png',
// });

L.Icon.Default.imagePath =
  "//cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/";

//

// let polyline;

interface EditControlExampleProps {
    data: any;
    onChange?: (event: any) => void;
    readOnly?: boolean;
}

  // see http://leaflet.github.io/Leaflet.draw/docs/leaflet-draw-latest.html#l-draw-event for leaflet-draw events doc
export default function EditControlExample(props: EditControlExampleProps) {
    // const [editableFG, setEditableFG] = useState<L.FeatureGroup<any> | null>(null);
    const fgRef = useRef<L.FeatureGroup<any> | null>(null);
    // const [currentLocation, setCurrentLocation] = useState(defaultLocation);
    // useEffect(() => {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(
    //             position =>
    //                 setCurrentLocation([position.coords.latitude, position.coords.longitude]))
    //     }
    // }, []);

    
    useEffect(() => {
        // const { current = {} } = fgRef;
        if (fgRef.current === null) {
            // console.error("reactFGref is null!");
            return;
        }
        // populate the leaflet FeatureGroup with the geoJson layers

        let leafletGeoJSON = new L.GeoJSON(props.data);

        leafletGeoJSON.eachLayer((layer) => {
            fgRef.current?.addLayer(layer);
        });

        // store the ref for future access to content

        // console.log("FG ready", fgRef);
        // setEditableFG(fgRef);
    }, /*[fgRef, props.data]*/);
    //   function onFeatureGroupReady(reactFGref: L.FeatureGroup<any> | null) {
    //     if (reactFGref === null) {
    //         console.error("reactFGref is null!");
    //         return;
    //     }
    //   // populate the leaflet FeatureGroup with the geoJson layers
  
    //   let leafletGeoJSON = new L.GeoJSON(props.data);
    //   let leafletFG = reactFGref;
  
    //   leafletGeoJSON.eachLayer((layer) => {
    //     leafletFG.addLayer(layer);
    //   });
  
    //   // store the ref for future access to content
  
    //   console.log("FG ready", reactFGref);
    //   setEditableFG(reactFGref);
    // };



    function onEdited(e: any) {
        let numEdited = 0;
        e.layers.eachLayer((layer: any) => {
          numEdited += 1;
        });
        console.log(`onEdited: edited ${numEdited} layers`, e);
    
        onChange();
      };
    
      function onCreated(e: any) {
        let type = e.layerType;
        let layer = e.layer;
        if (type === 'marker') {
          // Do marker specific actions
          console.log('onCreated: marker created', e);
        } /*else {
          console.log('onCreated: something else created:', type, e);
        }*/
        // Do whatever else you need to. (save to db; etc)
    
        onChange();
      };
    
      function onDeleted(e: any) {
        let numDeleted = 0;
        e.layers.eachLayer((layer: any) => {
          numDeleted += 1;
        });
        console.log(`onDeleted: removed ${numDeleted} layers`, e);
    
        onChange();
      };
    
      function onMounted(drawControl: any) {
        console.log('onMounted', drawControl);
      };
    
      function onEditStart(e: any) {
        console.log('onEditStart', e);
      };
    
      function onEditStop(e: any) {
        console.log('onEditStop', e);
      };
    
      function onDeleteStart(e: any) {
        console.log('onDeleteStart', e);
      };
    
      function onDeleteStop(e: any) {
        console.log('onDeleteStop', e);
      };



    //   function onFeatureGroupReady(reactFGref: L.FeatureGroup<any> | null) {
    //     if (reactFGref === null) {
    //         console.error("reactFGref is null!");
    //         return;
    //     }
    //   // populate the leaflet FeatureGroup with the geoJson layers
  
    //   let leafletGeoJSON = new L.GeoJSON(props.data);
    //   let leafletFG = reactFGref;
  
    //   leafletGeoJSON.eachLayer((layer) => {
    //     leafletFG.addLayer(layer);
    //   });
  
    //   // store the ref for future access to content
  
    //   console.log("FG ready", reactFGref);
    //   setEditableFG(reactFGref);
    // };
  
    function onChange() {
      // this.editableFG contains the edited geometry, which can be manipulated through the leaflet API
  
    //   const { onChange }: any = props;
  
      if (!fgRef || !props.onChange) {
        return;
      }
  
      const geojsonData: any =  fgRef.current?.toGeoJSON();
    //   console.log("geojsonData", geojsonData);
  
      if (geojsonData) {
          if (geojsonData.type === "FeatureCollection") {
              if (geojsonData.features && geojsonData.features.length > 0) {
                  if (geojsonData.features[0].type === "Feature") {
                    //   console.log("onChange", geojsonData.features[0].geometry);
                    props.onChange(geojsonData.features[0].geometry);
                  }
              } else {
                //   console.log("onChange", null);
                props.onChange(null);
              }
          }
      }
      // onChange(geojsonData);
    };
      
    //   const map = useMap();
    //   map.setView(currentLocation, currentLocation === defaultLocation ?  6 : 16);
        return (
          <MapContainer center={defaultLocation/*currentLocation*/} zoom={/*currentLocation !== defaultLocation ?  16 :*/ 6} zoomControl={false} style={{width: "100%", height: "50vh"}}
          // whenCreated={(map) => {
          //   const group = fgRef.current;
          //   if (group !== null && props.data?.features?.length) {
          //     console.log("Bounds: ", group.getBounds(), props.data);
          //     map.fitBounds(group.getBounds());
          //   }
          // }}
          >
            {/* <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
            /> */}
            <MapGeoPosition />
            <ReactLeafletGoogleLayer apiKey='AIzaSyDrRUToUCRXvvkVH1iwvdW-dSo7SX8dewM' type={'hybrid'} />
            <FeatureGroup
            ref={fgRef}
            //   ref={(reactFGref) => {
            //     if (reactFGref !== null) {onFeatureGroupReady(reactFGref);}
            //   }}
            >
              {!props.readOnly && <EditControl
                position="topright"
                onEdited={onEdited}
                onCreated={onCreated}
                onDeleted={onDeleted}
                onMounted={onMounted}
                onEditStart={onEditStart}
                onEditStop={onEditStop}
                onDeleteStart={onDeleteStart}
                onDeleteStop={onDeleteStop}
                draw={{
                    polyline: false,
                    rectangle: false,
                    circle: false,
                    marker: false,
                    circlemarker: false,
                }}
              />}
            </FeatureGroup>
          </MapContainer>
        );
}

// TODO consider MapContainer whenCreated(map => ...) instead
function MapGeoPosition(params: any) {
    const map = useMap();
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position =>
                    map.setView([position.coords.latitude, position.coords.longitude], 16))
        }
    }, [map]);
    return <></>
}