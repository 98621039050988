import React from "react";

import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// import useMediaQuery from '@material-ui/core/useMediaQuery';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DashboardIcon from "@material-ui/icons/Dashboard";
import HelpIcon from "@material-ui/icons/Help";
import HomeIcon from "@material-ui/icons/Home";
// import ListIcon from "@material-ui/icons/List";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import TodayIcon from "@material-ui/icons/Today";

import styled from "styled-components";
import Layout, {
  getHeader,
  getDrawerSidebar,
  getSidebarTrigger,
  getSidebarContent,
  getCollapseBtn,
  getContent,
  getFooter,
  getFullscreen,
  Root,
} from "@mui-treasury/layout";

import { Link as RouterLink, useHistory } from "react-router-dom";

import { useApolloClient } from "@apollo/client";

import { useAuth } from "./use-auth";


import { ReactComponent as ExoBuildLogoSymbolWhite } from "./symbol_rgb_white_transparent_background.svg";

const Header = getHeader(styled);
const DrawerSidebar = getDrawerSidebar(styled);
const SidebarTrigger = getSidebarTrigger(styled);
const SidebarContent = getSidebarContent(styled);
const CollapseBtn = getCollapseBtn(styled);
const Content = getContent(styled);
const Footer = getFooter(styled);
const Fullscreen = getFullscreen(styled);

const scheme = Layout();

scheme.configureHeader((builder) => {
  builder.create("whatever_id").registerConfig("xs", {
    position: "sticky",
    clipped: true,
    initialHeight: 64,
  });
  // .registerConfig('md', {
  //   position: 'relative', // won't stick to top when scroll down
  // });
});

scheme.configureEdgeSidebar((builder) => {
  builder
    .create("unique_id", { anchor: "left" })
    .registerTemporaryConfig("xs", {
      // anchor: 'left',
      width: "auto", // 'auto' is only valid for temporary variant
    })
    .registerPermanentConfig("md", {
      width: 256, // px, (%, rem, em is compatible)
      collapsible: true,
      collapsedWidth: 64,
    });
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // "@global": {
    //   body: {
    //     backgroundColor: theme.palette.common.white, // TODO What if dark mode?
    //   },
    // },
    root: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },
    grow: {
      flexGrow: 1,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(2),
      overflow: "auto",
    },
    footer: {
      flexGrow: 0,
      padding: theme.spacing(1),
    },
    listItemIcon: {
      width: theme.typography.pxToRem(24),
    },
    faIcon: {
      fontSize: theme.typography.pxToRem(24),
    },
    muiIcon: {
      fontSize: theme.typography.pxToRem(24),
    },
    paddingRight: {
      paddingRight: theme.spacing(1),
    },
  })
);

interface Props {
  drawerContent?: JSX.Element;
  children: any;
}

function DrawerLayout(props: Props) {
  const { children, drawerContent } = props;
  const classes = useStyles(props);
  const theme = useTheme() as Theme;
  // const fullWidth = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const { signedIn, signOut } = useAuth();
  const client = useApolloClient();

  async function handleSignOut(event?: any) {
    if (event) {
      event.preventDefault();
    }
    history.replace("/");
    // history.push(`/`);
    await signOut();
    await client.resetStore();
    window.location.reload();
  }

  return (
    // <div className={classes.root}>
    <Fullscreen>
    <Root theme={theme} scheme={scheme}>
      {({ state: { sidebar }, setOpen, setCollapsed }) => (
        <>
          <Header>
            <AppBar position="static">
              <Toolbar
              // variant="dense"
              >
                <SidebarTrigger sidebarId="unique_id" color="inherit" />
                <ExoBuildLogoSymbolWhite height={32} className={classes.paddingRight} />
                <Typography variant="h6" color="inherit" noWrap>
                  ExoFarm
                </Typography>
                <div className={classes.grow} />
                {/* <IconButton color="inherit">
                    <Badge badgeContent={4} color="secondary">
                      <NotificationsIcon />
                    </Badge>
                  </IconButton> */}
                {!signedIn ? (
                  <Button color="inherit" component={RouterLink} to="/login">
                    Login
                  </Button>
                ) : (
                  <Button color="inherit" onClick={handleSignOut}>
                    Logout
                  </Button>
                )}
              </Toolbar>
            </AppBar>
          </Header>
          <DrawerSidebar sidebarId="unique_id">
            <SidebarContent>
              {drawerContent ? (
                drawerContent
              ) : (
                    <List>
                      <ListItem
                        button
                        component={RouterLink}
                        to="/"
                        onClick={() => setOpen("unique_id", false)}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <HomeIcon className={classes.muiIcon} />
                        </ListItemIcon>
                        {!sidebar.unique_id.collapsed && (
                          <ListItemText primary={"Home"} />
                        )}
                      </ListItem>
                      {/* <ListItem
                        button
                        component={RouterLink}
                        to="/dashboard"
                        onClick={() => setOpen("unique_id", false)}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <DashboardIcon className={classes.muiIcon} />
                        </ListItemIcon>
                        {!sidebar.unique_id.collapsed && (
                          <ListItemText primary={"Dashboards"} />
                        )}
                      </ListItem> */}
                      <ListItem
                        button
                        component={RouterLink}
                        to="/field"
                        onClick={() => setOpen("unique_id", false)}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <LocationOnIcon className={classes.muiIcon} />
                        </ListItemIcon>
                        {!sidebar.unique_id.collapsed && (
                          <ListItemText primary={"Fields"} />
                        )}
                      </ListItem>
                      <ListItem
                        button
                        component={RouterLink}
                        to="/task"
                        onClick={() => setOpen("unique_id", false)}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <TodayIcon className={classes.muiIcon} />
                        </ListItemIcon>
                        {!sidebar.unique_id.collapsed && (
                          <ListItemText primary={"Tasks"} />
                        )}
                      </ListItem>
                      <ListItem
                        button
                        component={RouterLink}
                        to="/help"
                        onClick={() => setOpen("unique_id", false)}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <HelpIcon className={classes.muiIcon} />
                        </ListItemIcon>
                        {!sidebar.unique_id.collapsed && (
                          <ListItemText primary={"Help"} />
                        )}
                      </ListItem>
                    </List>
              )}
            </SidebarContent>
            <CollapseBtn />
          </DrawerSidebar>
          <Content>
            <main className={classes.content}>{children}</main>
          </Content>
          <Footer className={classes.footer}>
            <Typography variant="body1" noWrap>
              Copyright &copy; 2021 <Link href="https://www.exobuild.com" color="textSecondary">ExoBuild e.U.</Link>
            </Typography>
          </Footer>
        </>
      )}
    </Root>
    </Fullscreen>
  );
}

export default DrawerLayout;
