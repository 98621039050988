// import React from "react";

// import { makeVar/*, ReactiveVar*/ } from "@apollo/client";

// import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
    palette: {
        // type: "dark",
        primary: {
          main: "#164233",
        },
        secondary: {
          main: "#eecf78",
        },
      },
      typography: {
        fontFamily: [
          'Nunito',
        ].join(","),
      },
});

// // export const darkModeVar = makeVar<boolean>(false);

// // https://material-ui.com/customization/palette/#user-preference
// // https://www.apollographql.com/blog/apollo-client/caching/local-state-management-with-reactive-variables/
// export function useTheme (
//   // darkModeVar: ReactiveVar<boolean>,
// ) {
//   const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
//   // TODO If the user updates his browser settings to prefer dark mode, the reactive var should also update!
//   const darkModeVar = makeVar<boolean>(prefersDarkMode);

//   const darkMode = darkModeVar();

//   const theme = React.useMemo(
//     () =>
//     createMuiTheme({
//       palette: {
//           type: darkMode ? "dark" : "light",
//           primary: {
//             main: "#164233",
//           },
//           secondary: {
//             main: "#eecf78",
//           },
//         },
//         typography: {
//           fontFamily: [
//             'Nunito',
//           ].join(","),
//         },
//   }),
//     [darkMode],
//     );
//   // const toggleDarkMode = (/*darkMode: boolean*/) => {
//   //   const darkMode = darkModeVar();
//   //   return darkModeVar(!darkMode);
//   // }

//   return {
//     theme,
//     darkModeVar,
//     // toggleDarkMode
//     // operations: { toggleDarkMode }
//   }
// }

// Green: #164233
// Yellow: #eecf78
// Gray: #808080