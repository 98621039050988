import React from "react";

import { useParams } from "react-router";

import { useQuery } from "@apollo/client";

import task from "./task";

import TaskDetail from "./task-detail";

interface Props {}

function TaskDetailContainer(props: Props) {
  const { id } = useParams<any>();

  const { data /*, error*/ } = useQuery(task, {
    variables: { where: { id: id /*: entityId*/ } },
  });

  // if (error) return `Error! ${error.message}`;

  const entity = data ? data.task : undefined;

  return <TaskDetail entity={entity}></TaskDetail>;
}

export default TaskDetailContainer;
