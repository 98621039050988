import React from "react";

import { useParams } from "react-router";

import { useQuery } from "@apollo/client";

import field from "./field";

import FieldDetail from "./field-detail";

interface Props {}

function FieldDetailContainer(props: Props) {
  const { id } = useParams<any>();

  const { data /*, error*/ } = useQuery(field, {
    variables: { where: { id: id /*: entityId*/ } },
  });

  // if (error) return `Error! ${error.message}`;

  const entity = data ? data.field : undefined;

  return <FieldDetail entity={entity}></FieldDetail>;
}

export default FieldDetailContainer;
