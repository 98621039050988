import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  makeVar,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RestLink } from "apollo-link-rest";

import { AUTH_TOKEN, SERVER_API_URL, SERVER_URI } from "./constants";

// TODO https://www.apollographql.com/docs/react/advanced/boost-migration.html
// https://www.apollographql.com/docs/react/essentials/local-state#code-splitting

export const darkModeVar = makeVar<boolean>(false);

const cache = new InMemoryCache({
  // See https://www.apollographql.com/docs/react/caching/cache-configuration/#custom-identifiers
  // TODO typePolicies in apollo 3: https://blog.apollographql.com/previewing-the-apollo-client-3-cache-565fadd6a01e
  // dataIdFromObject: (object: any) => {
  //   switch (object.__typename) {
  //     case 'AllData': return object.tagId;
  //     case 'Band': return JSON.stringify(object);
  //     case 'Yaxes': return JSON.stringify(object);
  //     default: return defaultDataIdFromObject(object); // fall back to default handling
  //   }
  // }
  typePolicies: {
    Query: {
      fields: {
        darkMode: {
          read() {
            return darkModeVar();
          },
        },
      },
    },
  },
});

const credentials =
  SERVER_URI === `${window.location.protocol}//${window.location.host}`
    ? "same-origin"
    : "include";

const restLink = new RestLink({
  uri: `${SERVER_API_URL}/`,
  credentials,
});

const httpLink = new HttpLink({
  uri: `${SERVER_URI}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(AUTH_TOKEN);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    }),
    // requestLink,
    authLink,
    restLink,
    httpLink,
  ]),
  cache,
  // resolvers: { ... },
});
