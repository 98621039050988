// See https://github.com/mui-org/material-ui/blob/master/docs/src/pages/getting-started/page-layout-examples/sign-in/SignIn.js
// See https://github.com/graphql-boilerplates/react-fullstack-graphql/blob/master/advanced/src/components/LoginPage.js
import React, { useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
// import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Alert from "@material-ui/lab/Alert";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import { useApolloClient } from "@apollo/client";

import { useHistory } from "react-router";

import { useAuth } from "./use-auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // "@global": {
    //   body: {
    //     backgroundColor: theme.palette.common.white, // TODO What if dark mode?
    //   },
    // },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
);

interface Props {
  refreshTokenFn?: any;
}

function SignIn(props: Props) {
  const classes = useStyles(props);
  const history = useHistory();
  const client = useApolloClient();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const [remember, setRemember] = useState(false);
  const [error, setError] = useState(null);

  const { signIn } = useAuth();

  function handleUsernameChange(event: any) {
    setUsername(event.target.value);
  }
  function handlePasswordChange(event: any) {
    setPassword(event.target.value);
  }
  // function handleRememberChange(event: any, checked: boolean) {
  //   setRemember(checked);
  // }

  async function handleLogin(event?: any) {
    if (event) {
      event.preventDefault();
    }
    try {
      setError(null);
      await signIn(username, password);
    } catch (e) {
      console.error("Error signing in!", e);
      setError(e);
      return;
    }
    // TODO is it a good idea to reset the store after login? time range will be reset
    try {
      await client.resetStore();
      const lastUrl = (localStorage && localStorage.getItem("lastUrl")) || "/";
      history.push(lastUrl);
      window.location.reload();
    } catch (e) {
      console.error("Error resetting client store!", e);
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={handleUsernameChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={handlePasswordChange}
          />
          {/* <FormControlLabel
            control={
              <Checkbox
                value="remember"
                color="primary"
                checked={remember}
                onChange={handleRememberChange}
              />
            }
            label="Remember me"
          /> */}
          {error && (
            <Alert severity="error">
              Login failed. Either your user name or password was entered
              incorrectly. Please try again.
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleLogin}
          >
            Sign In
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default SignIn;
