import React from "react";

import { useMutation } from "@apollo/client";

import { startOfToday } from "date-fns";

import mapTaskValues from "./map-task-values";
import createTask from "./create-task";

import TaskCreate from "./task-create";

interface Props {
  onClose?: any;
  onSuccess?: any;
  onError?: any;
}

function TaskCreateContainer(props: Props) {
  const { onClose } = props;
  const defaultValues = {
    date: startOfToday(),
  };
  // default values
  const entity = defaultValues;

  const { onSuccess /*, onError*/ } = props;

  const [createTaskMutation] = useMutation(createTask);
  async function saveEntity(componentValues: any) {
    const { id, __typename, ...valuesRest } = componentValues;
    const options: any = {
      variables: { input: { ...mapTaskValues(valuesRest, "create") } },
      refetchQueries: ["tasks"],
    };
    /*const result =*/ await createTaskMutation(options);

    if (onSuccess) {
      onSuccess();
    }
  }

  return (
    <TaskCreate
      saveEntity={saveEntity}
      cancelEdit={onClose}
      entity={entity}
      defaultValues={defaultValues}
    ></TaskCreate>
  );
}

export default TaskCreateContainer;
