import React from "react";

import { useParams } from "react-router";

import { useQuery, useMutation } from "@apollo/client";

import field from "./field";

import mapFieldValues from "./map-field-values";
import updateField from "./update-field";

import FieldUpdate from "./field-update";

interface Props {
  onClose?: any;
  onSuccess?: any;
  onError?: any;
}

function FieldUpdateContainer(props: Props) {
  const { onClose } = props;

  const { id } = useParams<any>();

  const defaultValues = {};

  const { data /*, error*/ } = useQuery(field, {
    variables: { where: { id: id /*: entityId*/ } },
  });

  // if (error) return `Error! ${error.message}`;

  const entity = data ? data.field : undefined;
  const { /*entity,*/ onSuccess /*, onError*/ } = props;

  const [updateFieldMutation] = useMutation(updateField);
  async function saveEntity(componentValues: any) {
    const { /*id, */__typename, ...valuesRest } = componentValues;
    const options: any = {
      variables: { input: { ...mapFieldValues(valuesRest, "update") } },
      refetchQueries: ["fields"],
    };
    options.variables.where = { id: entity.id };
    /*const result =*/ await updateFieldMutation(options);

    if (onSuccess) {
      onSuccess();
    }
  }

  return (
    <FieldUpdate
      saveEntity={saveEntity}
      cancelEdit={onClose}
      entity={entity}
      defaultValues={defaultValues}
    ></FieldUpdate>
  );
}

export default FieldUpdateContainer;
