import React from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";

import Field from "./field-routes";
import Task from "./task-routes";

import MainPage from "./main-page";
import LoginPage from "./login-page";
import SignupPage from "./signup-page";

import DrawerLayout from "./drawer-layout";

interface Props {}

function Routes(props: Props) {
  return (
    <DrawerLayout>
      <Switch>
        <Route path="/field">
          <Field />
        </Route>
        <Route path="/task">
          <Task />
        </Route>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/signup">
          <SignupPage />
        </Route>
        <Route exact path="/">
          <MainPage />
        </Route>{" "}
      </Switch>
    </DrawerLayout>
  );
}
export default Routes;
