import React from "react";

import { useParams } from "react-router";

import { useQuery, useMutation } from "@apollo/client";

import field from "./field";

import deleteField from "./delete-field";

import FieldDelete from "./field-delete";

interface Props {
  onClose?: any;
  onSuccess?: any;
  onError?: any;
}

function FieldDeleteContainer(props: Props) {
  const { id } = useParams<any>();

  const { onClose } = props;

  const { data /*, error*/ } = useQuery(field, {
    variables: { where: { id: id /*: entityId*/ } },
  });

  // if (error) return `Error! ${error.message}`;

  const entity = data ? data.field : undefined;
  const { /*entity,*/ onSuccess /*, onError*/ } = props;

  const [deleteFieldMutation] = useMutation(deleteField);
  // TODO componentDidMount: this.props.getEntity(match!.params.id);
  async function confirmDelete(event?: any) {
    const options: any = {
      variables: { where: { id: entity.id } },
      refetchQueries: ["fields"],
    };
    /*const result =*/ await deleteFieldMutation(options);

    if (onSuccess) {
      onSuccess(event);
    }
  }

  return (
    <FieldDelete confirmDelete={confirmDelete} onClose={onClose}></FieldDelete>
  );
}

export default FieldDeleteContainer;
