import React from "react";

interface Props {
  entity: { [key: string]: any };
}

function TaskDefinitionList(props: Props) {
  const { entity } = props;
  return (
    <dl>
      <dt>Id</dt>
      <dd>{JSON.stringify(entity.id, null, 2)}</dd>
      <dt>Type</dt>
      <dd>{JSON.stringify(entity.type, null, 2)}</dd>
      <dt>Title</dt>
      <dd>{JSON.stringify(entity.title, null, 2)}</dd>
      <dt>Date</dt>
      <dd>{JSON.stringify(entity.date, null, 2)}</dd>
      <dt>Fields</dt>
      <dd></dd>
      <dt>Product</dt>
      <dd>{JSON.stringify(entity.product, null, 2)}</dd>
      <dt>Amount</dt>
      <dd>{JSON.stringify(entity.amount, null, 2)}</dd>
      <dt>Unit</dt>
      <dd>{JSON.stringify(entity.unit, null, 2)}</dd>
    </dl>
  );
}

export default TaskDefinitionList;
