import * as React from "react";

import {
  DateTimePicker, // KeyboardDateTimePicker
} from "@material-ui/pickers";

import { FieldRenderProps } from "react-final-form";

interface Props extends FieldRenderProps<any, any> {
  label?: string;
  // formControlProps?: FormControlProps,
}

function DateTimeFieldRender(props: Props) {
  const {
    input,
    meta,
    label,
    // formControlProps,
    ...rest
  } = props;
  const { name, value, onChange, ...restInput } = input;
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <DateTimePicker
      name={name}
      label={label}
      helperText={showError ? meta.error || meta.submitError : undefined}
      error={showError}
      // inputProps={restInput}
      onChange={(date) =>{ console.log("date changed", date);
        input.onChange(date as unknown as React.ChangeEvent<any>);}
      }
      value={value}
      {...rest}
    />
  );
}

export default DateTimeFieldRender;
