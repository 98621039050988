import React, { Suspense } from "react";

import { ApolloProvider } from "@apollo/client";
import { client } from "./apollo-client";

import { ProvideAuth } from "./use-auth";

import { BrowserRouter as Router } from "react-router-dom";

import "typeface-nunito";

import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { theme } from "./theme";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { Helmet } from "react-helmet";

import { IntlProvider } from "react-intl";

// TODO import React, { Suspense } from 'react';

import Routes from "./routes";

const language = navigator.language.split(/[-_]/)[0] || "en"; // language without region code

// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
// import { AppContainer } from 'react-hot-loader';

function App() {
  return (
    <ApolloProvider client={client}>
      <ProvideAuth>
        <Router>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <>
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>ExoFarm</title>
                </Helmet>
                <IntlProvider
                  locale={language}
                  // messages={messages}
                >
                  <Suspense fallback={<div>Loading...</div>}>
                    <Routes />
                  </Suspense>
                </IntlProvider>
              </>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Router>
      </ProvideAuth>
    </ApolloProvider>
  );
}

export default App;
