import React, { useState, useEffect, useContext, createContext } from "react";

import { loader } from "graphql.macro";

import { AUTH_TOKEN, SERVER_URI } from "./constants";

import { signin, signout } from "./security-service";
import { useQuery, useApolloClient } from "@apollo/client";

// TODO generator
// const me = loader('./graphql/me.graphql');

const credentials =
  SERVER_URI === `${window.location.protocol}//${window.location.host}`
    ? "same-origin"
    : "include";

interface AuthContextProps {
  user: any;
  signedIn: boolean;
  signIn: (username: string, password: string) => Promise<any>;
  // signUp: (username: string, password: string) => Promise<any>
  signOut: () => Promise<void>;
  // sendPasswordResetEmail: any
  // confirmPasswordReset: any
}

const AuthContext = createContext<AuthContextProps>({
  user: null,
  signedIn: false,
  signIn: (username: string, password: string) => Promise.resolve(null),
  signOut: () => Promise.resolve(),
});

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
interface ProvideAuthProps {
  children: any; // JSX.Element[]
}
export function ProvideAuth(props: ProvideAuthProps) {
  const auth = useProvideAuth();
  return (
    <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>
  );
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(AuthContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  // const [user, setUser] = useState(!loading && data && data.me && data.me.id ? data.me : null);
  const [signedIn, setSignedIn] = useState(false);
  const [user, setUser] = useState(null);
  const client = useApolloClient();
  // TODO generator
  // const { data, loading /*, error*/ } = useQuery(me);
  /*useQuery(me, {
    onCompleted: (data: any) => {
      if (data && data.me && data.me.id) {
        setUser(data.me);
        setSignedIn(true);
      }
    }
  });*/

  async function signIn(username: string, password: string) {
    await signin(username, password);
    // TODO check result! (e.g., wrong password etc.)
    setSignedIn(true);
    // // useQuery(me, {
    // //   onCompleted: (data: any) => {
    // //     if (data && data.me && data.me.id) {
    // //       setUser(data.me);
    // //     }
    // //   }
    // // });

    // // const { data, loading } =
    // // await client.query<any>({
    // //   query: me,
    // //   onCompleted: (data: any) => {
    // //     if (data && data.me && data.me.id) {
    // //       setUser(data.me);
    // //     }
    // //   }
    // // });
    // const { data, loading } = await client.query<any>({
    //   query: me,
    //   // onCompleted: (data: any) => {
    //   //   if (data && data.me && data.me.id) {
    //   //     setUser(data.me);
    //   //   }
    //   // }
    // });
    // console.log("me", data, loading);

    // // const { data /*, error*/ } = useQuery(me);
    // if (!loading && data && data.me && data.me.id) {
    //   setUser(data.me);
    //   return data.me;
    // }
  }

  async function signOut() {
    await signout();
    setUser(null);
    setSignedIn(false);
  }

  // https://github.com/trojanowski/react-apollo-hooks/issues/158 => useMemo?
  // useEffect(() => {

  // }, []);

  // Return the user object and auth methods
  return {
    user,
    signedIn,
    signIn,
    // signUp,
    signOut,
    // sendPasswordResetEmail,
    // confirmPasswordReset
  };
}
