import { /*loader,*/ gql } from "graphql.macro";

const updateTask = gql`
  mutation updateTask($input: TaskUpdateInput!, $where: TaskWhereUniqueInput!) {
    updateTask(input: $input, where: $where)
      @rest(type: "Task", path: "tasks/{args.where.id}", method: "PUT") {
      id
      type
      title
      date
      fields
      product
      amount
      unit
    }
  }
`;

export default updateTask;
