import React from "react";

import { useMutation } from "@apollo/client";

import mapFieldValues from "./map-field-values";
import createField from "./create-field";

import FieldCreate from "./field-create";

interface Props {
  onClose?: any;
  onSuccess?: any;
  onError?: any;
}

function FieldCreateContainer(props: Props) {
  const { onClose } = props;
  const defaultValues = {};
  // default values
  const entity = defaultValues;

  const { onSuccess /*, onError*/ } = props;

  const [createFieldMutation] = useMutation(createField);
  async function saveEntity(componentValues: any) {
    const { id, __typename, ...valuesRest } = componentValues;
    const options: any = {
      variables: { input: { ...mapFieldValues(valuesRest, "create") } },
      refetchQueries: ["fields"],
    };
    /*const result =*/ await createFieldMutation(options);

    if (onSuccess) {
      onSuccess();
    }
  }

  return (
    <FieldCreate
      saveEntity={saveEntity}
      cancelEdit={onClose}
      entity={entity}
      defaultValues={defaultValues}
    ></FieldCreate>
  );
}

export default FieldCreateContainer;
