import { /*loader,*/ gql } from "graphql.macro";

const deleteTask = gql`
  mutation deleteTask($where: TaskWhereUniqueInput!) {
    deleteTask(where: $where)
      @rest(type: "Task", path: "tasks/{args.where.id}", method: "DELETE") {
      id
    }
  }
`;

export default deleteTask;
