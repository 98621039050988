export const AUTH_TOKEN = "auth-token";
export const SERVER_URI =
  process.env.NODE_ENV === "production"
    ? `${window.location.protocol}//${window.location.host}`
    : "http://localhost:8080";
// export const SERVER_URI = `${window.location.protocol}//${window.location.host}`;
// export const SERVER_URI = "http://localhost:8080";
export const SERVER_API_URL = `${SERVER_URI}/api`;
export const SENTRY_DSN =
  "https://dc2f64940c9442cc9bc50e3af0797181@sentry.io/1773255";
