import { AUTH_TOKEN, SERVER_API_URL, SERVER_URI } from "./constants";

const credentials =
  SERVER_URI === `${window.location.protocol}//${window.location.host}`
    ? "same-origin"
    : "include";

function _confirm(data: any) {
  const { id_token: token } = /*signin ?*/ data.signin; /*: data.signup*/
  _saveUserData(token);
  // history.push(`/`);
}

function _saveUserData(token: string) {
  // console.log("saving token", token);
  localStorage.setItem(AUTH_TOKEN, token);
}

// const [loginMutation] = useMutation(LOGIN_USER_MUTATION, {
//     onCompleted: (data) => _confirm(data)
// });

// async function loginGraphQL() {
//     const result: any = await loginMutation({
//         variables: {
//             input: {
//                 username,
//                 password
//             }
//         }
//     });

//     const token = result.data.login.access_token;
//     console.log("Login result: ", result.data.login);
//     refreshTokenFn &&
//         refreshTokenFn({
//             [AUTH_TOKEN]: token // TODO refresh_token?
//         });
//     // history.replace("/");
//     // window.location.reload();
// }

async function handleResponse(response: Response) {
  const text = await response.text();
  // console.log("GOT Text: ", text);
  const data = text && JSON.parse(text);
  // console.log("GOT data: ", data);
  if (!response.ok) {
    if (response.status === 401) {
      // TODO auto logout if 401 response returned from api
      clearToken();
      window.location.reload(true);
    }

    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  }

  return { signin: data };
}

function clearToken() {
  // remove user from local storage to log user out
  localStorage.removeItem(AUTH_TOKEN);
}

export async function signin(username: string, password: string) {
  const requestOptions: RequestInit = {
    method: "POST",
    credentials,
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Basic ${window.btoa(username + ":" + password)}`,
    },
    body: JSON.stringify({ username, password }),
  };

  const response = await fetch(
    `${SERVER_API_URL}/authenticate`,
    requestOptions
  );
  const data = await handleResponse(response);
  _confirm(data);
  if (data.signin) {
    return data.signin;
  }
}

// TODO could also be a graphql mutation!
export async function signout() {
  const requestOptions: RequestInit = {
    method: "POST",
    credentials,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${SERVER_API_URL}/logout`,
    requestOptions
  );
  /*const data =*/ await handleResponse(response);
  return clearToken();
}
