import React, { useState } from "react";

import { useHistory, useLocation } from "react-router";

import { useQuery } from "@apollo/client";

import tasks from "./tasks";

import TaskCalendar from "./task-calendar";
import TaskTable from "./task-table";

interface Props {}

function TaskTableContainer(props: Props) {
  const location = useLocation();
  const history = useHistory();
  function handleView(value: any, event?: any) {
    if (event) {
      event.stopPropagation();
    }
    history.push(`${location.pathname}/${value.id}`);
    // history.push(`${match.url}/${value.id}`);
  }
  function handleEdit(value: any, event?: any) {
    if (event) {
      event.stopPropagation();
    }
    history.push(`${location.pathname}/${value.id}/edit`);
    // history.push(`${match.url}/${value.id}/edit`);
  }
  function handleDelete(value: any, event?: any) {
    if (event) {
      event.stopPropagation();
    }
    history.push(`${location.pathname}/${value.id}/delete`);
    // history.push(`${match.url}/${value.id}/delete`);
  }

  const [variables, setVariables] = useState({});

  const { data /*, error*/, loading } = useQuery(tasks, {
    variables,
  });

  // if (error) return `Error! ${error.message}`;

  const dataSource = data ? data.tasks : undefined;
  const totalElements = data ? data.taskCount : undefined;
  function changeVariables(newVariables: any) {
    if (JSON.stringify(variables) !== JSON.stringify(newVariables)) {
      setVariables(newVariables);
    }
  }
  return (
    <TaskCalendar
      dataSource={dataSource}
      totalElements={totalElements}
      changeVariables={changeVariables}
      loading={loading}
      onView={handleView}
      onEdit={handleEdit}
      onDelete={handleDelete}
    ></TaskCalendar>
    // <TaskTable
    //   dataSource={dataSource}
    //   totalElements={totalElements}
    //   changeVariables={changeVariables}
    //   loading={loading}
    //   onView={handleView}
    //   onEdit={handleEdit}
    //   onDelete={handleDelete}
    // ></TaskTable>
  );
}

export default TaskTableContainer;
