import React from "react";

import { useHistory, useLocation } from "react-router";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import AddIcon from "@material-ui/icons/Add";

import TaskTable from "./task-table";
import TaskCreate from "./task-create";
import TaskDetail from "./task-detail";
import TaskUpdate from "./task-update";
import TaskDelete from "./task-delete";

import TaskTableContainer from "./task-table-container";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  })
);

interface Props {}

function TaskOverview(props: Props) {
  const location = useLocation();
  const history = useHistory();

  const classes = useStyles(props);
  function handleCreateClick(event: any) {
    // history.push(`${match.url}/new`);
    history.push(`${location.pathname}/new`);
    event.stopPropagation();
  }

  function handleClose(event?: any) {
    if (event) {
      event.stopPropagation();
    }
    history.goBack();
  }
  // TODO remove 90%
  return (
    <div style={{height: "90%"}}>
      <Grid justify="space-between" alignItems="center" container>
        <Grid item>
          <Typography variant="h5">Tasks</Typography>
        </Grid>
        <Grid item>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleCreateClick}
          >
            Create
          </Button>
        </Grid>
      </Grid>
      <TaskTableContainer></TaskTableContainer>
    </div>
  );
}

export default TaskOverview;
