export default function (
  values: any,
  method: string,
  originalValues: any = {}
): any {
  const result: any = {};
  result.id = values ? values.id : undefined;
  result.name = values ? values.name : undefined;
  result.location = values ? values.location : undefined;
  return result;
}
