import { /*loader,*/ gql } from "graphql.macro";

const updateField = gql`
  mutation updateField(
    $input: FieldUpdateInput!
    $where: FieldWhereUniqueInput!
  ) {
    updateField(input: $input, where: $where)
      @rest(type: "Field", path: "fields/{args.where.id}", method: "PUT") {
      id
      name
      location
    }
  }
`;

export default updateField;
