import React from "react";

import { useHistory /*, useRouteMatch*/ } from "react-router";
import { Route, Switch } from "react-router-dom";

import FieldOverview from "./field-overview";
import FieldCreateContainer from "./field-create-container";
import FieldDetailContainer from "./field-detail-container";
import FieldUpdateContainer from "./field-update-container";
import FieldDeleteContainer from "./field-delete-container";

interface Props {}

// TODO hooks and use useRouteMatch as in https://reacttraining.com/blog/react-router-v5-1/#useroutematch
function Routes(props: Props) {
  // const location = useLocation();
  const history = useHistory();
  return (
    <>
      <Switch>
        <Route exact path="/field">
          <FieldOverview />
        </Route>
        <Route exact path="/field/new">
          <FieldCreateContainer
            onClose={() => history.goBack()}
            onSuccess={async () => {
              await history.push("/field");
              window.location.reload();
            }}
          />
        </Route>
        <Route exact path="/field/:id/edit">
          <FieldUpdateContainer
            onClose={() => history.goBack()}
            onSuccess={() => history.goBack()}
            // onSuccess={async() => { await history.push("/field"); window.location.reload();}}
          />
        </Route>
        <Route exact path="/field/:id">
          <FieldDetailContainer
          // onClose={() => history.goBack()}
          // onSuccess={() => history.push("/field")}
          />
        </Route>{" "}
      </Switch>
      <Route path="/field/:id/delete">
        <FieldDeleteContainer
          onClose={() => history.goBack()}
          onSuccess={async () => {
            await history.push("/field");
            window.location.reload();
          }}
        />
      </Route>{" "}
    </>
  );
}
export default Routes;
