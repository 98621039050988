import { /*loader,*/ gql } from "graphql.macro";

const task = gql`
  query task($where: TaskWhereUniqueInput!) {
    task(where: $where) @rest(type: "Task", path: "tasks/{args.where.id}") {
      id
      type
      title
      date
      fields
      product
      amount
      unit
    }
  }
`;

export default task;
