import React from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";

import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import createDecorator from "final-form-focus";

import Typography from "@material-ui/core/Typography";

import Grid from "@material-ui/core/Grid";

import { TextField } from "mui-rff";

import { GeoJsonPolygon } from "./GeoJsonPolygonField";

const focusOnErrors = createDecorator();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  })
);

interface Props {
  saveEntity: any;
  entity?: any;
  defaultValues?: any; // TODO entityCreateContainerGenerator
  cancelEdit?: any;
}

function FieldCreate(props: Props) {
  const { saveEntity, entity /*, defaultValues*/ } = props; // TODO entityCreateContainerGenerator
  const { cancelEdit } = props;
  const classes = useStyles(props);

  const validate = (values: any) => {
    const errors = {} as any;

    // TODO also subfields! (like widget layout)
    if (
      !("name" in values && values.name !== undefined && values.name !== null)
    ) {
      errors.name = "Required";
    }
    // TODO also subfields! (like widget layout)
    if (
      !(
        "location" in values &&
        values.location !== undefined &&
        values.location !== null
      )
    ) {
      errors.location = "Required";
    }
    return errors;
  };

  return (
    <Form
      onSubmit={saveEntity}
      decorators={[focusOnErrors]}
      initialValues={entity}
      mutators={{
        ...arrayMutators,
      }}
      validate={validate}
      render={({ handleSubmit, pristine, invalid, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            {/* Hide ID */}
            <Grid item xs={12}>
              <TextField
                name="name"
                type="text"
                label="Name"
                // margin="normal"
                required
                autoFocus
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <GeoJsonPolygon
                name="location"
                type="text"
                label="Location"
                // margin="normal"
                required
                autoFocus
                fullWidth
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            type="submit"
            disabled={submitting || invalid}
          >
            Submit
          </Button>
          {cancelEdit && (
            <Button
              variant="contained"
              className={classes.button}
              onClick={cancelEdit}
            >
              Cancel
            </Button>
          )}
        </form>
      )}
    />
  );
}

export default FieldCreate;
