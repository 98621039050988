import React from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";

import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import createDecorator from "final-form-focus";

import Typography from "@material-ui/core/Typography";

import Grid from "@material-ui/core/Grid";

import MenuItem from "@material-ui/core/MenuItem";

import SelectFieldRender from "./select-field-render";

import { TextField } from "mui-rff";

import DateTimeFieldRender from "./date-time-field-render";

const focusOnErrors = createDecorator();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  })
);

interface Props {
  saveEntity: (values: any) => void;
  entity?: any;
  defaultValues?: any;
  cancelEdit?: any;
}

function TaskUpdate(props: Props) {
  const { saveEntity, entity, defaultValues, cancelEdit } = props;
  const classes = useStyles(props);

  const validate = (values: any) => {
    const errors = {} as any;

    // TODO also subfields! (like widget layout)
    if (
      !("type" in values && values.type !== undefined && values.type !== null)
    ) {
      errors.type = "Required";
    }
    // TODO also subfields! (like widget layout)
    if (
      !(
        "title" in values &&
        values.title !== undefined &&
        values.title !== null
      )
    ) {
      errors.title = "Required";
    }
    // TODO also subfields! (like widget layout)
    if (
      !("date" in values && values.date !== undefined && values.date !== null)
    ) {
      errors.date = "Required";
    }
    // TODO also subfields! (like widget layout)
    if (
      !(
        "fields" in values &&
        values.fields !== undefined &&
        values.fields !== null
      )
    ) {
      errors.fields = "Required";
    }
    return errors;
  };

  return (
    <Form
      onSubmit={saveEntity}
      decorators={[focusOnErrors]}
      initialValues={entity}
      mutators={{
        ...arrayMutators,
      }}
      validate={validate}
      render={({ handleSubmit, pristine, invalid, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            {/* Hide ID */}
            <Grid item xs={12}>
              <Field
                name="type"
                component={SelectFieldRender}
                label="Type"
                fullWidth
                formControlProps={{
                  fullWidth: true,
                  required: true,
                }}
              >
                <MenuItem value="UNKNOWN">Unknown</MenuItem>
                <MenuItem value="TILLAGE">Tillage</MenuItem>
                <MenuItem value="SOWING_AND_PLANTING">
                  Sowing / Planting
                </MenuItem>
                <MenuItem value="FERTILIZING">Fertilizing</MenuItem>
                <MenuItem value="CROP_PROTECTION">Crop Protection</MenuItem>
                <MenuItem value="HARVESTING">Harvesting</MenuItem>
                <MenuItem value="IRRIGATION">Irrigation</MenuItem>
              </Field>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="title"
                type="text"
                label="Title"
                // margin="normal"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="date"
                component={DateTimeFieldRender}
                label="Date"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="product"
                type="text"
                label="Product"
                // margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={8} sm={4}>
              <TextField
                name="amount"
                type="text"
                label="Amount"
                // margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={4} sm={2}>
              <TextField
                name="unit"
                type="text"
                label="Unit"
                // margin="normal"
                fullWidth
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            type="submit"
            disabled={submitting || invalid}
          >
            Submit
          </Button>
          {cancelEdit && (
            <Button
              variant="contained"
              className={classes.button}
              onClick={cancelEdit}
            >
              Cancel
            </Button>
          )}
        </form>
      )}
    />
  );
}

export default TaskUpdate;
