import * as React from "react";

import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

import { FieldRenderProps } from "react-final-form";

interface Props extends FieldRenderProps<any, any> {
  label?: string;
  formControlProps?: FormControlProps;
}

function SelectFieldRender(props: Props) {
  const {
    input: { name, value, onChange, ...restInput },
    meta,
    label,
    formControlProps,
    ...rest
  } = props;
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <FormControl {...formControlProps} error={showError}>
      <InputLabel htmlFor={name}>{label}</InputLabel>

      <Select
        {...rest}
        name={name}
        onChange={onChange}
        inputProps={restInput}
        value={value}
      />

      {showError && (
        <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
      )}
    </FormControl>
  );
}

export default SelectFieldRender;
