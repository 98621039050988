import { /*loader,*/ gql } from "graphql.macro";

const createField = gql`
  mutation createField($input: FieldCreateInput!) {
    createField(input: $input)
      @rest(type: "Field", path: "fields/", method: "POST") {
      id
      name
      location
    }
  }
`;

export default createField;
