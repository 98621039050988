import React from "react";

import { useHistory /*, useRouteMatch*/ } from "react-router";
import { Route, Switch } from "react-router-dom";

import TaskOverview from "./task-overview";
import TaskCreateContainer from "./task-create-container";
import TaskDetailContainer from "./task-detail-container";
import TaskUpdateContainer from "./task-update-container";
import TaskDeleteContainer from "./task-delete-container";

interface Props {}

// TODO hooks and use useRouteMatch as in https://reacttraining.com/blog/react-router-v5-1/#useroutematch
function Routes(props: Props) {
  // const location = useLocation();
  const history = useHistory();
  return (
    <>
      <Switch>
        <Route exact path="/task">
          <TaskOverview />
        </Route>
        <Route exact path="/task/new">
          <TaskCreateContainer
            onClose={() => history.goBack()}
            onSuccess={async () => {
              await history.push("/task");
              window.location.reload();
            }}
          />
        </Route>
        <Route exact path="/task/:id/edit">
          <TaskUpdateContainer
            onClose={() => history.goBack()}
            onSuccess={() => history.goBack()}
            // onSuccess={async() => { await history.push("/task"); window.location.reload();}}
          />
        </Route>
        <Route exact path="/task/:id">
          <TaskDetailContainer
          // onClose={() => history.goBack()}
          // onSuccess={() => history.push("/task")}
          />
        </Route>{" "}
      </Switch>
      <Route path="/task/:id/delete">
        <TaskDeleteContainer
          onClose={() => history.goBack()}
          onSuccess={async () => {
            await history.push("/task");
            window.location.reload();
          }}
        />
      </Route>{" "}
    </>
  );
}
export default Routes;
