import React from "react";

interface Props {
  entity: { [key: string]: any };
}

function FieldDefinitionList(props: Props) {
  const { entity } = props;
  return (
    <dl>
      <dt>Id</dt>
      <dd>{JSON.stringify(entity.id, null, 2)}</dd>
      <dt>Name</dt>
      <dd>{JSON.stringify(entity.name, null, 2)}</dd>
      <dt>Location</dt>
      <dd>{JSON.stringify(entity.location, null, 2)}</dd>
    </dl>
  );
}

export default FieldDefinitionList;
