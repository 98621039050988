import React from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import { useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import FieldDefinitionList from "./field-definition-list";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  })
);

interface Props {
  entity: { [key: string]: any };
}

function FieldDetail(props: Props) {
  const location = useLocation();

  const { entity } = props;
  const classes = useStyles(props);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid justify="space-between" alignItems="center" container>
          <Grid item>
            <Typography variant="h5">{entity && entity.name}</Typography>
          </Grid>
          <Grid item>
            <Button
              startIcon={<EditIcon />}
              variant="contained"
              color="primary"
              className={classes.button}
              component={RouterLink}
              to={`${location.pathname}/edit`}
            >
              Edit
            </Button>
            <Button
              startIcon={<DeleteIcon />}
              variant="contained"
              color="primary"
              className={classes.button}
              component={RouterLink}
              to={`${location.pathname}/delete`}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {entity && <FieldDefinitionList entity={entity}></FieldDefinitionList>}
      </Grid>
    </Grid>
  );
}

export default FieldDetail;
