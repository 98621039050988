import { /*loader,*/ gql } from "graphql.macro";

const field = gql`
  query field($where: FieldWhereUniqueInput!) {
    field(where: $where) @rest(type: "Field", path: "fields/{args.where.id}") {
      id
      name
      location
    }
  }
`;

export default field;
