import React from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

import DeleteIcon from "@material-ui/icons/Delete";

import { FormattedMessage } from "react-intl";

// TODO https://github.com/mui-org/material-ui/blob/master/docs/src/pages/demos/dialogs/ConfirmationDialog.js

interface Props {
  entity?: any;
  confirmDelete?: any;
  onClose?: any;
}

// Like https://github.com/brunobertolini/styled-by
// const styledBy = (property, mapping) => props => mapping[props[property]];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  })
);

function FieldDelete(props: Props) {
  const { /*entity,*/ confirmDelete, onClose } = props;
  const classes = useStyles(props);
  return (
    <Dialog open onClose={onClose} aria-labelledby="confirmation-dialog-title">
      <DialogTitle id="confirmation-dialog-title">
        Confirm delete operation
      </DialogTitle>
      <DialogContent>Are you sure you want to delete this Field?</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default" className={classes.button}>
          <FormattedMessage id="entity.action.cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          startIcon={<DeleteIcon />}
          variant="contained"
          onClick={confirmDelete}
          color="primary"
          className={classes.button}
        >
          <FormattedMessage id="entity.action.delete" defaultMessage="Delete" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FieldDelete;
